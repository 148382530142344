#homeHero {
	min-height: 492px;
	background-position: 30% 100%;

	@include breakpoint(641px, up) {
		background-position: 30% 100%;
		min-height: 703px;
	}
	@include breakpoint(large, up) {
		background-position: top center;
		min-height: 914px;
	}
	background-repeat: no-repeat;
	background-size: cover;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
	overflow: hidden;

	h1 {
		color: $white;
		text-align: center;
		position: relative;
		z-index: 100;
	}
}

span.decoration {
	height: 100%;
	background: $orange;
	position: absolute;
	display: block;
	transform: skewX(12deg);
	top: 0;
	width: 216px;
	right: -180px;
	z-index: 5;

	@include breakpoint(641px, up) {
		right: -120px;
	}
}

#homeLessons {
	max-width: $global-max-width;
	margin: 0 auto;
	padding-top: 3rem;
	padding-bottom: 3rem;

	@include breakpoint(641px, up) {
		padding-top: 6rem;
		padding-bottom: 6rem;
	}

	@include mq(m, up) {
		.learndash-course-grid {
			*:is(.items-wrapper) {
				grid-template-columns: repeat(4, 1fr) !important;
				column-gap: 20px;
			}
		}
	}

	@include breakpoint(large, up) {
		padding-top: 8rem;
		padding-bottom: 8rem;
	}

	h2 {
		color: $bronze;
		margin-bottom: 1rem;
	}
	h3.entry-title {
		font-family: $body-font-family;

		a {
			color: $orange;
		}
	}
	.entry-content {
		color: $black;

		p {
			text-align: left;
		}
	}

	a.button {
		margin: 0 auto;
		position: relative;
		transform: translateX(-50%);
		left: 50%;
	}
}

#homeAbout,
section.about-section {
	background: $navy;
	position: relative;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-top: 3rem;
	padding-bottom: 3rem;
	min-height: 529px;

	@include breakpoint(641px, up) {
		min-height: 635px;
	}
	@include breakpoint(large, up) {
		min-height: 741px;
	}
	.inner {
		max-width: 885px;
		margin: 0 auto;
		z-index: 100;
		position: relative;
		width: 100%;
	}
	h2 {
		color: $white;
		text-align: left;
	}

	.content {
		padding-left: 1rem;
		border-left: 2px solid $white;
		color: $white;
		margin-left: 1.3rem;

		a.button {
			margin-top: 1rem !important;
		}
	}
}

#homeArtists {
	background: $white;
	position: relative;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-top: 3rem;
	padding-bottom: 3rem;
	min-height: 529px;

	@include breakpoint(641px, up) {
		min-height: 635px;
		padding-top: 6rem;
		padding-bottom: 6rem;
	}
	@include breakpoint(large, up) {
		padding-top: 8rem;
		padding-bottom: 8rem;
	}
	.inner {
		max-width: $global-max-width;
		margin: 0 auto;
		z-index: 100;
		width: 100%;
	}
	h2 {
		color: $bronze;
		text-align: left;
		margin-bottom: 3rem;
	}

	.artists-container {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		flex-wrap: wrap;
		width: 100%;

		.artist {
			display: inline-block;
			text-decoration: none;
			width: 48%;
			padding-right: 0.5rem;
			position: relative;

			@include breakpoint(medium) {
				width: 25%;
				padding-right: 0.5rem;
			}

			@include breakpoint(large) {
				width: 25%;
				padding-right: 1rem;
			}

			.image {
				position: relative;
				overflow: hidden;

				span.decoration {
					width: 55px;
					right: -45px;
				}

				img {
					width: 100%;
				}
			}

			h3 {
				color: $orange;
				font-family: $body-font-family;
				font-weight: 700;
				margin-top: 1rem;
				margin-bottom: 0;
			}
			p {
				margin-top: 0;
				color: $black;
				font-weight: 300;

				span {
					font-weight: 500;
				}
			}
		}
	}

	a.button {
		margin-top: 1rem !important;
		position: relative;
		transform: translateX(-50%);
		left: 50%;
	}
}
#homeResearch,
section.photo-left,
section.photo-right {
	background: $navy;
	position: relative;
	overflow: hidden;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding-top: 3rem;
	padding-bottom: 3rem;
	padding: 0;
	flex-wrap: wrap;

	@include breakpoint(850px) {
		flex-wrap: nowrap;
	}

	.image {
		width: 100%;
		height: 0;
		position: relative;
		overflow: hidden;
		height: 100%;

		@include breakpoint(850px) {
			width: 50%;
		}

		&:after {
			content: " ";
			position: absolute;
			display: block;
			width: 250px;
			height: 100%;
			top: 0;
			right: -250px;
			z-index: -1;
			background: $navy;
			border-bottom: none;
			border-right: 20px solid $navy;
			border-top: none;
			border-left: none;
			transform-origin: bottom left;
			transform: skew(15deg, 0deg);
			z-index: 100;
		}

		figure {
			margin: 0;
		}
	}
	.inner {
		margin: 0 auto;
		z-index: 100;
		position: relative;
		width: 100%;
		height: 100%;
		padding-left: $padding-xs;
		padding-right: $padding-xs;

		@include breakpoint(500px) {
			padding-left: $padding-sm;
			padding-right: $padding-sm;
		}
		@include breakpoint(medium) {
			padding-left: $padding-md;
			padding-right: $padding-md;
		}
		@include breakpoint(1024px) {
			padding-left: $padding-lg;
			padding-right: $padding-lg;
		}
		@include breakpoint(850px) {
			width: 50%;
		}
	}
	h2 {
		color: $white;
		text-align: left;
		margin-top: 1rem;
	}

	.content {
		border-left: 2px solid $white;
		color: $white;
		margin-left: 1.3rem;
		max-width: 775px;
		padding-left: 1rem;
		padding-right: 1rem;
		margin-bottom: 2rem;

		a.button {
			margin-top: 1rem !important;
		}
	}
}
section.photo-left,
section.photo-right {
	h2 {
		color: $bronze;
	}
	.content {
		border-left: 2px solid $navy;
		color: $black;
	}
	.image:after {
		width: 100px;
		@include breakpoint(medium) {
			width: 250px;
		}
	}
}
section.photo-left {
	background: $white;
	.image:after {
		border-right: 20px solid $white;
		transform: skew(-15deg, 0deg);
		right: 0;
		background: $white;
	}
}
section.photo-right {
	background: $white;

	.inner {
		margin-left: 3rem;
	}
	.image:after {
		top: 0;
		right: auto;
		left: 0;
		background: $white;
		border-right: none;
		border-left: 20px solid $white;
		transform-origin: bottom right;
		transform: skew(15deg, 0deg);
		z-index: 100;
	}
}

#homeHero {
	position: relative;
}
.background-video {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
	overflow: hidden;
	pointer-events: none;

	.video {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		aspect-ratio: 16 / 9;
	}
}
