.resource-container {
	width: 100%;
	border: 2px solid $gray;
	max-width: 1135px;
	display: flex;
	justify-content: space-between;
	flex-direction: row;
	margin: 0 auto 2rem;
	flex-wrap: nowrap;

	.file-row {
		a {
			margin: 0.5rem 0;
			display: block;
			svg {
				margin-left: 0.5rem;
			}
		}
	}
	.inner {
		flex-grow: 2;
	}
	.content {
		padding: 1rem 0 1rem 1rem;
	}

	h2 {
		color: $orange;
		font-family: $body-font-family;
		font-weight: bold;
		@include fluid-prop(font-size, 17px, 25px);
	}

	.image {
		position: relative;
		overflow: hidden;
		padding: 0;
		margin: 0;
		flex-shrink: 0;
		flex-basis: 35%;
		display: none;

		@include mq(m) {
			display: inline-block;
		}
		&:after {
			content: " ";
			position: absolute;
			display: block;
			height: 100%;
			top: 0;
			right: auto;
			left: 0;
			z-index: -1;
			background: $white;
			border-bottom: none;
			border-right: 20px solid $white;
			border-top: none;
			border-left: none;
			transform-origin: bottom left;
			transform: skew(15deg, 0deg);
			z-index: 100;
			width: 50px;

			@include breakpoint(medium) {
				width: 100px;
			}
		}
		img {
			position: absolute;
			object-fit: cover;
			height: 100%;
			width: 100%;
		}
	}
}
