/**
 * Buttons
 **/

.btn,
.button,
#learndash_registerform .button-primary {
	background: none;
	border: none;
	line-height: 1;
	margin: 0.5em;
	padding: 0.8rem 1.2rem;
	color: $white;
	text-decoration: none;
	transition: all ease-in-out 0.4s;
	text-transform: uppercase;
	font-weight: 700;
	position: relative;
	@include fluid-prop(font-size, 13px, 20px);
	display: inline-block;

	@include breakpoint(large) {
		padding: 0.4rem 1.2rem;
	}

	&:after {
		position: absolute;
		top: 0;
		right: 0;
		background: url(../images/button-triangle.svg) no-repeat center center;
		background-size: cover;
		height: 100%;
		width: 11px;
		display: block;
		z-index: 100;
		content: " ";
	}

	&.blue,
	&.btn-primary {
		background: $blue;
	}
	&.white {
		background: $white;
		color: $navy;
	}

	&.slide:hover,
	&.slide:focus {
		box-shadow: inset -300px 0 0 0 $orange;
		color: $white;
	}
}

.ld-course-list-items .ld_course_grid .btn {
	margin: 0 !important;
}
