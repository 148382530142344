$white: #ffffff;
$black: #000000;
$gray: #a2a2a2;
$light-gray: #d1d1d1;

$blue: #33647e;
$navy: #233d7c;
$bronze: #9c8311;
$peach: #dba175;
$orange: #d06239;

$primary-color: $navy;
$secondary-color: $orange;
$tertiary-color: $bronze;
$quaternary-color: $blue;
