#aboutHero {
	min-height: 179px;
	background-position: 30% 100%;

	@include breakpoint(641px, up) {
		background-position: 30% 100%;
		min-height: 370px;
	}
	@include breakpoint(large, up) {
		background-position: top center;
		min-height: 560px;
	}
	background-repeat: no-repeat;
	background-size: cover;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
	overflow: hidden;

	h1 {
		color: $white;
		text-align: center;
		position: relative;
		z-index: 100;
	}

	span.decoration {
		width: 140px;
		right: -120px;
		z-index: 5;

		@include breakpoint(medium) {
			width: 160px;
			right: -120px;
		}
		@include breakpoint(large) {
			width: 180px;
			right: -120px;
		}
	}
}

#aboutPhotos {
	background: $white;
	position: relative;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-top: 3rem;
	padding-bottom: 3rem;
	min-height: 529px;

	@include breakpoint(641px, up) {
		min-height: 635px;
		padding-top: 6rem;
		padding-bottom: 6rem;
	}
	@include breakpoint(large, up) {
		padding-top: 6rem;
		padding-bottom: 6rem;
	}

	.inner {
		max-width: 975px;
		margin: 0 auto;
		z-index: 100;
		width: 100%;
		text-align: left;

		h2 {
			color: $bronze;
			margin-bottom: 2rem;
		}
	}

	.photos-row {
		display: flex;
		justify-content: center;
		margin-bottom: 3rem;
		flex-wrap: wrap;

		.image-container {
			margin: 0 0 1rem 0;
		}

		.image {
			height: 0;
			position: relative;
			overflow: hidden;
			height: 100%;
			width: 130px;
			margin: 0 1rem;

			@include breakpoint(medium) {
				width: 219px;
			}

			@include breakpoint(large) {
				width: 308px;
			}

			&:after {
				content: " ";
				position: absolute;
				display: block;
				width: 250px;
				height: 100%;
				top: 0;
				right: -250px;
				z-index: -1;
				background: $orange;
				border-bottom: none;
				border-right: 20px solid $orange;
				border-top: none;
				border-left: none;
				transform-origin: bottom left;
				transform: skew(15deg, 0deg);
				z-index: 100;
			}

			figure {
				margin: 0;
			}
		}
	}
}

.full-photo {
	background: $navy;
	position: relative;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-top: 3rem;
	padding-bottom: 3rem;
	min-height: 529px;

	@include breakpoint(641px, up) {
		min-height: 635px;
	}
	@include breakpoint(large, up) {
		min-height: 741px;
	}
	.inner {
		max-width: 885px;
		margin: 0 auto;
		z-index: 100;
		position: relative;
		width: 100%;
	}
	h2 {
		color: $white;
		text-align: left;
	}

	.content {
		padding-left: 1rem;
		border-left: 2px solid $white;
		color: $white;
		margin-left: 1.3rem;

		a.button {
			margin-top: 1rem !important;
		}
	}
}
