.page,
.single {
	.entry-content {
		max-width: $global-width;
		width: 100%;
		margin: 0 auto;
		padding: 0 12px;
	}
}

.error404 {
	.main-content-full-width {
		position: relative;
		min-height: 100vh;
		display: flex;
		flex-direction: column;
		justify-content: center;
		color: $white;
		align-content: center;
		overflow: hidden;

		div {
			max-width: 800px;
			margin: 0 auto;
			text-align: center;
			position: relative;
			z-index: 100;
			padding: 0 2rem;

			h1 {
				color: $white;
			}
		}
	}
	footer.footer-container {
		margin-top: 0;
	}
}
