footer.footer-container {
	display: flex;
	flex-direction: column;
	background: #e5e5e5;
	// margin-top: 3rem;
/*
	@include breakpoint(641px, up) {
		margin-top: 6rem;
	}
	@include breakpoint(large, up) {
		margin-top: 8rem;
	} */
	h4 {
		color: $orange;
		font-family: $body-font-family;
		font-weight: 700;
	}

	.inner {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		width: 100%;
		max-width: $global-max-width;
		margin: 0 auto;
		padding-top: 2rem;
		padding-bottom: 2rem;
		@include breakpoint(small, down) {
			padding: 2rem 3rem !important;
		}
		flex-wrap: wrap;
		@include breakpoint(medium) {
			flex-wrap: nowrap;
		}
		.left-menu,
		.right-menu {
			width: 100%;
			order: 2;

			@include breakpoint(medium) {
				width: 25%;
				order: 1;
			}
		}

		.logos {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			width: 100%;

			@include breakpoint(medium) {
				width: 50%;
			}
			justify-content: space-around;
			align-items: flex-end;
			align-content: center;
			order: 1;

			@include breakpoint(large) {
				order: 3;
			}

			.logo {
				max-width: 215px;
				margin-right: 1rem;
				margin-bottom: 2rem;

				@include breakpoint(medium) {
					max-width: 300px;
				}
			}
		}
		ul {
			list-style: none;
			margin: 0;
			padding: 0;
			li {
				margin: 1rem 0;
				padding: 0;

				a {
					color: $black;
					@include transition;
					&:hover {
						color: lighten($black, 20%);
					}
				}
			}
		}
	}

	.bottom-bar {
		background: $navy;
		padding: 1rem 0;

		.inner {
			width: 100%;
			max-width: $global-max-width;
			margin: 0 auto;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			flex-wrap: wrap;
			padding: 0;

			@include breakpoint(medium) {
				flex-wrap: nowrap;
			}

			a {
				color: $white;

				@include breakpoint(small, down) {
					width: 100%;
					margin-bottom: 1rem;
				}
			}
		}
	}
}
