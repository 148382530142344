.artist-container {
	width: 100%;
	background: #f2f2f2;
	margin-bottom: 3rem;
	margin-top: 3rem;

	@include breakpoint(medium, up) {
		padding: 0 0 3rem;
	}

	.inner {
		max-width: $global-max-width;
		margin: 0 auto;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;

		.artist-image {
			background: $white;
			margin-top: -1rem;
			border: 1px solid #707070;
			height: auto;
			align-self: flex-start;
			width: 100%;

			@include breakpoint(medium) {
				width: 355px;
			}
			@include breakpoint(large) {
				width: 425px;
			}

			.inner-container {
				width: 100%;
				padding: 1rem;
				margin-left: auto;
				margin-right: auto;

				@include breakpoint(medium) {
					width: 355px;
				}
				@include breakpoint(large) {
					width: 425px;
				}
			}

			.image {
				position: relative;
				overflow: hidden;
				width: 100%;
				height: auto;
				img {
					width: 100%;
				}

				span.decoration {
					width: 55px;
					right: -45px;
				}
			}

			h2 {
				color: $orange;
				font-family: $body-font-family;
				font-weight: 700;
				margin: 0.5rem 0;
				@include fluid-prop(font-size, 17px, 25px);
			}
			.social-links {
				a {
					margin-right: 0.5rem;
				}
				a.website-link {
					text-decoration: underline;
					float: right;
					margin: 0;
				}
			}
		}
	}
	.artist-content {
		width: 100%;
		padding: 1rem 0;

		@include breakpoint(medium) {
			width: calc(100% - 355px);
			padding: 1rem 1rem 1rem 2rem;
		}
		@include breakpoint(large) {
			width: calc(100% - 425px);
		}

		h3 {
			color: $orange;
			font-family: $body-font-family;
			font-weight: 700;
			margin: 0.5rem 0;
			@include fluid-prop(font-size, 17px, 25px);
		}

		.photos {
			display: flex;
			justify-content: flex-start;
			margin-bottom: 3rem;
			flex-wrap: wrap;
			flex-direction: row;

			.image {
				height: 0;
				position: relative;
				overflow: hidden;
				height: 100%;
				width: calc(50% - 0.5rem);

				&:nth-child(odd) {
					margin-right: 1rem;
				}
				@include breakpoint(medium) {
					width: 25%;
					margin: 0 1rem;
				}
				img {
					margin: 0 0 1rem 0;
				}
			}
		}
	}
}
