.featured-hero {
	background: $navy;
	padding-top: 1rem;
	padding-bottom: 1rem;
	margin-bottom: 2rem;

	h1 {
		color: $white;
		text-align: center;
	}
}
