.ld-course-list-items.row {
	margin: 0;

	h3.entry-title {
		font-family: $body-font-family;
		color: $orange;
		font-weight: bold;
		@include fluid-prop(font-size, 17px, 25px);
		min-height: 90px;
	}
}

.ld_course_grid_price {
	display: none;
}
.ld-course-list-items .ld_course_grid .thumbnail .caption p {
	//width: 94% !important;
	//position: absolute;
	//bottom: .5rem;
	margin-right: 0;
}


.ld-course-list-items .ld_course_grid .thumbnail.course {
	min-height: 355px;
}

.ld-course-list-items .ld_course_grid .thumbnail.course a.btn-primary {
	background: $blue !important;
	border: none !important;
	border-radius: 0;
	font-weight: bold;

	&:hover,
	&:focus {
		box-shadow: inset -500px 0 0 0 $orange;
		color: $white;
	}
}
.ld-course-list-items .ld_course_grid .ld_course_grid_button {
	width: 96%;
	margin: 0 auto;
}

article.post-9 {
	background: $navy;
}

#learndash_registerform {
	label {
		color: $white;
		font-weight: bold;
//		text-transform: uppercase;
	}
}
#learndash-registration-wrapper {
	color: $white;
	padding: 1rem 0;

	a {
		color: $white;
	}
	.order-overview {
		display: none;
	}
}

.learndash-course-grid .entry-content {
	text-align: left !important;
	margin: 0;
}
