.ld-registration__register, .ld-password-strength__hint {
    color: $white;

    a {
        color: $white;
    }
}

.ld-registration__heading {
    display: none;
}