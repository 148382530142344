#topBar {
	background: $navy;
	padding: 0 $padding;

	nav {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		align-items: center;
		width: 100%;
		margin: 0 auto;
		max-width: $global-max-width;
		justify-content: space-between;

		@include breakpoint(large) {
			justify-content: flex-end;
		}
		.learndash-wrapper {
			margin-right: 2rem;
		}

		.ld-login-button {
			border: none !important;
			background: none !important;

			span {
				display: none;
			}
		}

		a {
			padding: 0.75rem 0;
			font-family: $body-font-family;
			font-weight: 500;
			@include fluid-prop(font-size, 15px, 20px);
			color: $white;
			text-decoration: none;
			@include transition;
			&:hover {
				color: darken($white, 20%);
			}
		}
	}
}
#headerBar {
	background: $white;

	.inner {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		margin: 0 auto;
		max-width: $global-max-width;
		padding: 1rem $padding;

		svg {
			@include fluid-prop(width, 150px, 220px);
		}

		ul {
			list-style: none;
			flex-direction: row;
			display: none;
			@include breakpoint(large) {
				display: flex;
			}
			li {
				a {
					font-size: var(--fluid-body-xs);
					color: $navy;
					text-decoration: none;
					padding: 0 2rem;
					@include transition;

					&:last-child {
						padding-right: 0;
					}

					&:hover {
						color: lighten($navy, 20%);
					}
				}
			}
		}
	}

	#mobileButton {
		display: block;
		background: none;
		border: none;
		cursor: pointer;

		@include breakpoint(large, up) {
			display: none;
		}

		svg {
			width: 27px;
			height: 16px;

			path {
				@include transition;
			}

			&:hover {
				path {
					fill: lighten($navy, 20%);
				}
			}
		}
	}
}

#mobileMenu {
	display: none;
}

body.active {
	overflow-y: hidden;

	#mobileMenu {
		position: fixed;
		background: $white;
		width: 100%;
		height: 100%;
		z-index: 120;
		position: fixed;
		overflow-y: scroll;
		top: 0;
		left: 0;
		display: block;

		.inner {
			height: 100%;
			width: 100%;
			min-height: 500px;
			display: flex;
			flex-direction: row;
			align-content: space-between;
			flex-wrap: wrap;
			padding-top: 125px;
		}
	}

	#mobileClose {
		display: flex;
		position: absolute;
		top: 60px;
		right: 1.5rem;
		border: 0;
		padding: 0;
		background-color: transparent;
		height: 35px;
		width: 35px;
		text-align: center;
		cursor: pointer;
		transition: 0.15s;
		color: $black;
		z-index: 100;
		font-size: 3rem;
		font-weight: 700;
		line-height: 1;

		svg {
			height: 40px;
			width: 40px;
			position: relative;
			top: 0;
			path {
				fill: $navy;
				@include transition;
			}
			&:hover {
				path {
					fill: $gray;
				}
			}
		}
	}

	#mobileNavButton {
		display: none !important;
	}
	#mobileLogo {
		display: block;
		margin: 0 auto 2rem;

		svg {
			width: 100%;
			@include fluid-prop(width, 153px, 320px);
		}
	}
	#menuNav {
		display: flex;
		flex-direction: column;
		width: 100%;
	}
	.menu-links ul {
		flex-direction: column;

		li {
			width: 100%;
			text-align: center;
			margin-bottom: 2rem;

			a {
				color: $navy;
				font-family: $body-font-family;
				text-decoration: none;
				font-size: 1.5rem;
				@include transition;

				&:hover {
					color: lighten($navy, 20%);
				}
			}
		}
	}
}
